import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getReferringSizeConditions() {
    return axiosIns.get('/admin/referring_size_conditions')
  },
  createReferringSizeCondition({
    adjective,
    threshold,
    referralId,
  }) {
    const formData = serialize(snakecaseKeys({
      referringSizeCondition: {
        adjective,
        threshold,
        referralId,
      },
    }))

    return axiosIns.post('/admin/referring_size_conditions', formData)
  },
  updateReferringSizeCondition({
    id,
    adjective,
    threshold,
    referralId,
  }) {
    const formData = serialize(snakecaseKeys({
      referringSizeCondition: {
        adjective,
        threshold,
        referralId,
      },
    }))

    return axiosIns.put(`/admin/referring_size_conditions/${id}`, formData)
  },
  deleteReferringSizeCondition(id) {
    return axiosIns.delete(`/admin/referring_size_conditions/${id}`)
  },
}
