var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('common-conditions',{attrs:{"headers-ext":_vm.headers,"items":_vm.referringSizeConditions,"validator":function (currentForm) { return !!currentForm.referralId; },"threshold-suffix":"件","is-submitting":_vm.isSubmitting},on:{"submit":_vm.submit,"cancel":_vm.cancelForm,"edit":function($event){_vm.currentForm = Object.assign({}, {id: $event.id}, $event.attributes)},"destroy":function($event){return _vm.destroy($event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"value":_vm.currentForm,"items":_vm.referrals,"item-value":function (item) {
          if (item.attributes) { return { referralId: item.id } }
        },"value-comparator":function (a, b) {
          if (a && b) { return +a.referralId === +b.referralId }
        },"item-text":"attributes.name","label":"ターゲット","hide-details":"","error":!_vm.currentForm.referralId,"no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.referralId = $event.referralId}}})]},proxy:true},{key:"group-header",fn:function(ref){
        var groupBy = ref.groupBy;
        var group = ref.group;
return [(groupBy[0] === 'meta.targetName')?_c('strong',[_vm._v(" "+_vm._s(group)+" ")]):_vm._e()]}}]),model:{value:(_vm.currentForm),callback:function ($$v) {_vm.currentForm=$$v},expression:"currentForm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }