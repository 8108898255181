<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <common-conditions
      v-model="currentForm"
      :headers-ext="headers"
      :items="referringSizeConditions"
      :validator="currentForm => !!currentForm.referralId"
      threshold-suffix="件"
      :is-submitting="isSubmitting"
      @submit="submit"
      @cancel="cancelForm"
      @edit="currentForm = { id: $event.id, ...$event.attributes }"
      @destroy="destroy($event)"
    >
      <template #input>
        <v-select
          :value="currentForm"
          :items="referrals"
          :item-value="item => {
            if (item.attributes) return { referralId: item.id }
          }"
          :value-comparator="(a, b) => {
            if (a && b) return +a.referralId === +b.referralId
          }"
          item-text="attributes.name"
          label="ターゲット"
          hide-details
          :error="!currentForm.referralId"
          no-data-text="選択可能なデータがありません"
          :menu-props="{ auto: true, offsetY: true }"
          @change="currentForm.referralId = $event.referralId"
        />
      </template>

      <template #group-header="{ groupBy, group }">
        <strong v-if="groupBy[0] === 'meta.targetName'">
          {{ group }}
        </strong>
      </template>
    </common-conditions>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import { findIndex } from 'lodash'
import ReferringSizeConditionApi from '@/api/admin/ReferringSizeCondition'
import ReferralApi from '@/api/waiter/Referral'
import useCompInit from '@/views/composable/useCompInit'
import CommonConditions from '@/views/components/pay_condition/CommonConditions.vue'

export default {
  components: {
    CommonConditions,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isSubmitting = ref(false)
    const currentForm = ref({
      id: null,
      adjective: null,
      threshold: 0,
      referralId: null,
    })
    const referringSizeConditions = ref([])
    const referrals = ref([])

    const cancelForm = () => {
      currentForm.value = {
        id: null,
        adjective: null,
        threshold: 0,
        referralId: null,
      }
    }

    const submit = async () => {
      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        adjective,
        threshold,
        referralId,
      } = currentForm.value

      const res = await ReferringSizeConditionApi[`${fnVerb}ReferringSizeCondition`]({
        id,
        adjective,
        threshold,
        referralId,
      })

      if (res?.data) {
        const { data } = res.data.referringSizeCondition

        if (isUpdate) {
          const referringSizeConditionIdx = findIndex(referringSizeConditions.value, o => +o.id === +data.id)
          referringSizeConditions.value.splice(referringSizeConditionIdx, 1, data)
        } else {
          referringSizeConditions.value.push(data)
        }

        vm.$toast.success(isUpdate ? '条件を更新しました' : '条件を追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async id => {
      const res = await ReferringSizeConditionApi.deleteReferringSizeCondition(id)

      if (res) {
        const referringSizeConditionIdx = findIndex(referringSizeConditions.value, o => +o.id === +id)
        referringSizeConditions.value.splice(referringSizeConditionIdx, 1)
        vm.$toast.success('条件を削除しました')
      }
    }

    const getReferringSizeConditions = async () => {
      const res = await ReferringSizeConditionApi.getReferringSizeConditions()

      if (res?.data) {
        referringSizeConditions.value = [...res.data.referringSizeConditions.data]
      }
    }

    const getReferrals = async () => {
      const res = await ReferralApi.getReferrals()

      if (res?.data) {
        referrals.value = [...res.data.referrals.data]
      }
    }

    initWith([
      getReferringSizeConditions(),
      getReferrals(),
    ])

    return {
      // data
      isLoading,
      isSubmitting,
      currentForm,
      referringSizeConditions,
      referrals,
      headers: [
        { text: 'ターゲット', value: 'meta.targetName' },
      ],

      // methods
      cancelForm,
      submit,
      destroy,
    }
  },
}
</script>
