import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getReferrals() {
    return axiosIns.get('/waiter/referrals')
  },
  createReferral({ name, isVendingContributionTrigger }) {
    const formData = serialize(snakecaseKeys({
      referral: { name, isVendingContributionTrigger },
    }))

    return axiosIns.post('/waiter/referrals', formData)
  },
  updateReferral({
    id, name, isVendingContributionTrigger,
  }) {
    const formData = serialize(snakecaseKeys({
      referral: { name, isVendingContributionTrigger },
    }))

    return axiosIns.put(`/waiter/referrals/${id}`, formData)
  },
  deleteReferral(id) {
    return axiosIns.delete(`/waiter/referrals/${id}`)
  },
  updatePositions(idAndPositions) {
    const formData = serialize(snakecaseKeys({
      referrals: idAndPositions,
    }))

    return axiosIns.put('/waiter/referrals/update_positions', formData)
  },
}
